<script lang="ts">
  import type { PortableText } from "@portabletext/svelte";

  interface Props {
    portableText: PortableText;
  }

  let { portableText }: Props = $props();
</script>

<li>
  {portableText.children[0].text}
</li>
