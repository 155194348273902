import Image from "$lib/portableText/Image.svelte";
import YouTubeEmbed from "$lib/portableText/YouTubeThumbnailLink.svelte";
import InlineReference from "$lib/portableText/InlineReference.svelte";
import SourceReference from "$lib/portableText/SourceReference.svelte";
import TextHighlighted from "$lib/portableText/TextHighlighted.svelte";
import ChecklistWrapper from "$lib/portableText/ChecklistWrapper.svelte";
import Link from "$lib/portableText/Link.svelte";
import TypeformEmbed from "$lib/portableText/TypeformEmbed.svelte";
// import CustomBulletItemWrapper from "./CustomBulletItemWrapper.svelte";

export const contentBlockComponents = {
  types: {
    image: Image,
    youtube: YouTubeEmbed,
    productReference: InlineReference,
    articleReference: InlineReference,
    fileReference: InlineReference,
    sourceReference: SourceReference,
    typeformEmbed: TypeformEmbed,
  },
  marks: {
    link: Link,
    highlight: TextHighlighted,
  },
  list: {
    check: ChecklistWrapper,
  },
  // listItem: {
  //   normal: CustomBulletItemWrapper,
  // },
};
