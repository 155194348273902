<script lang="ts">
  import type { CustomBlockComponentProps } from "@portabletext/svelte";
  import type { YouTubeEmbed } from "../types/shared";
  import Picture from "$lib/SanityPicture.svelte";
  import { getSanityPictureObject } from "$lib/utils/getSanityPictureObject";
  import { breakpoints as br } from "$lib/utils/getBreakpoints";

  interface Props {
    portableText: CustomBlockComponentProps<YouTubeEmbed>;
  }

  let { portableText }: Props = $props();

  let url = $derived(portableText?.value?.url ? portableText.value.url : "");
  let title = $derived(portableText?.value?.title ? portableText.value.title : "");
  let image = $derived(portableText?.value && {
    ...getSanityPictureObject(portableText?.value?.thumbnail, {
      sizes: [
        [810, 456],
        [1620, 911],
      ],
    }),
  });
</script>

{#if title && typeof image === "object"}
  <p class="mt-6 mb-2">
    <a target="_blank" href={url} referrerpolicy="no-referrer" class="aspect-video">
      <span class="thumbnail-container block relative aspect-video overflow-hidden">
        <strong aria-hidden="true" class="youtube-video-title">{title}</strong>
        <Picture
          pictureObject={image}
          alt={title}
          sizes={`(min-width: ${br.desktop}) 65vw, (min-width: ${br.laptop}) 75vw, 95vw`}
          class="w-full aspect-video"
        />
      </span>
      <span class="block text-sm mt-2 mb-6 text-center">(Lenke til Youtube)</span>
    </a>
  </p>
{/if}

<style lang="postcss">
  a {
    --_s: 48px;
    isolation: isolate;
    position: relative;
  }

  .thumbnail-container {
    border-radius: 1rem;
    /* display */
    display: grid;
    place-items: center;

    /* play button like triangle with CSS */
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="-1 0 24 24" fill="white" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"><polygon points="5 3 19 12 5 21 5 3"/></svg>');
      width: var(--_s); /* Adjust as needed */
      height: var(--_s); /* Adjust as needed */
    }

    /* play button background */
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      border-radius: 1rem;
      width: calc(3.5em + var(--_s)); /* Adjust as needed */
      height: calc(1.5em + var(--_s)); /* Adjust as needed */
      background: var(--naust);
      filter: drop-shadow(0 0 0.5em rgb(0 0 0 / 0.75));
      transition: filter 0.125s var(--easing);
    }
  }

  .youtube-video-title {
    border-radius: 1rem;
    font-size: 1rem;
    position: absolute;
    inset: auto var(--s-2) var(--s-2);
    z-index: 3;
    padding: var(--s-3);
    color: white;
    background: rgb(0 0 0 / 0.625);
    text-align: center;
    /* blur background */
    backdrop-filter: blur(1px);
    isolation: isolate;

    &::after {
      content: "";
      background: black;
      position: absolute;
      inset: 0;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.125s var(--easing);
    }
  }

  @media (--laptop) {
    a:hover .youtube-video-title::after {
      opacity: 1;
    }

    a:hover .thumbnail-container::after {
      filter: drop-shadow(0 0 0 rgb(0 0 0 / 0.75));
    }
  }

  a :global(img) {
    object-fit: cover;
  }
</style>
