import type { ImageFormat, FitMode, SanityImageHotspot } from "@sanity/image-url/lib/types/types";
import { client as fallbackClient } from "$lib/sanity/client";
import imageUrlBuilder from "@sanity/image-url";

type ImageOpts = {
  width?: number;
  height?: number;
  fit?: FitMode;
  format?: ImageFormat;
  hotspot?: SanityImageHotspot;
  quality?: number;
  srcsetW?: boolean;
  crop?: {
    _type: string;
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
};

/**
 * Returns a Sanity image URL that must be created after database result
 *
 * @param id - Sanity image ID
 * @param opt - Options for the image
 * @param [record] - (optional) Sanity image record
 * @param [client] - (optional) Sanity client
 * @returns - URL of the image
 */
export const getSanityImageSrc = (
  id: string,
  opt: ImageOpts,
  record?: { [key: string]: unknown; },
  client
): string => {
  client = typeof client === "undefined" ? undefined : client;
  const builder = imageUrlBuilder(client || fallbackClient);
  const urlFor = (source) => builder.image(source); // source is an image record or an image ID
  const format: ImageFormat = opt?.format ? opt.format : "jpg";
  const defaultFit = "min";
  const quality = opt?.quality ? opt.quality : 80;

  // IF A SANITY IMAGE RECORD, HOTSPOT/CROP (SHOULD BE/)IS USED FROM THE RECORD
  // width, height
  if (record && opt?.width && opt?.height) {
    return `${urlFor(record)
      .format(format)
      .width(opt.width)
      .height(opt.height)
      .fit(opt?.fit || defaultFit)
      .quality(quality)
      .url()}${!opt?.srcsetW ? "" : ` ${opt.width}w`}`;
  }
  // width
  else if (record && opt?.width)
    return `${urlFor(record)
      .format(format)
      .width(opt.width)
      .fit(opt?.fit || defaultFit)
      .quality(quality)
      .url()}${!opt?.srcsetW ? "" : ` ${opt.width}w`}`;
  // height
  else if (record && opt?.height)
    return `${urlFor(record)
      .format(format)
      .height(opt.height)
      .fit(opt?.fit || defaultFit)
      .quality(quality)
      .url()}${!opt?.srcsetW ? "" : ` ${opt.width}w`}`;
  // none
  else if (record) return `${urlFor(record).format(format).fit(defaultFit).url()}`;
  // ELSE NOT RECORD
  // width, height, hotspot
  else if (opt?.width && opt?.height && opt?.hotspot?.x && opt?.hotspot?.y)
    return `${urlFor(id)
      .format(format)
      .width(opt.width)
      .height(opt.height)
      .focalPoint(opt.hotspot.x, opt.hotspot.y)
      .crop("focalpoint")
      .fit(opt?.fit || defaultFit)
      .quality(quality)
      .url()}${!opt?.srcsetW ? "" : ` ${opt.width}w`}`;
  // width, height
  else if (opt?.width && opt?.height)
    return `${urlFor(id).format(format).width(opt.width).height(opt.height).fit(opt?.fit || defaultFit).quality(quality).url()}${!opt?.srcsetW ? "" : ` ${opt.width}w`}`;
  // width
  else if (opt?.width && !opt?.height)
    return `${urlFor(id)
      .format(format)
      .width(opt.width)
      .fit(opt?.fit || defaultFit)
      .quality(quality)
      .url()}${!opt?.srcsetW ? "" : ` ${opt.width}w`}`;
  // height
  else if (!opt?.width && opt?.height)
    return `${urlFor(id)
      .format(format)
      .height(opt.height)
      .fit(opt?.fit || defaultFit)
      .quality(quality)
      .url()}${!opt?.srcsetW ? "" : ` ${opt.width}w`}`;

  return String(urlFor(id).format(format).fit(defaultFit).url());
};

export default getSanityImageSrc;
