<script lang="ts">
  import type { MarkComponentProps } from "@portabletext/svelte";
  import Button from "$lib/Button.svelte";

  
  interface Props {
    // Property custom marks receive from @portabletext/svelte when redered
    portableText: MarkComponentProps<{
    href?: string;
    blank?: boolean;
    style?: string;
    arrow?: boolean;
  }>;
    children?: import('svelte').Snippet;
  }

  let { portableText, children }: Props = $props();

  // Remember to make your variables reactive so that they can reflect prop changes
  // See: https://svelte.dev/docs#3_$_marks_a_statement_as_reactive
  let { value } = $derived(portableText);
  let blank = $derived(value.blank || false);
  let defaultStyle = $derived(!value.style || !!value.style?.match(/link/));
  let arrow = $derived(value.arrow || false);
</script>

{#if value.href}
  <Button
    {arrow}
    {defaultStyle}
    class={value.style}
    href={value.href}
    target={blank ? "_blank" : undefined}
    rel={blank ? "noopener noreferrer" : undefined}
  >
    <!-- Marks receive children which you can render with Svelte's slots -->
    {@render children?.()}
  </Button>
{:else}
  <!-- If no valid URL, render only the contents of the mark -->
  {@render children?.()}
{/if}
