<script lang="ts">
  import type { MarkComponentProps } from "@portabletext/svelte";
  import * as pkg from '@typeform/embed';
  import '@typeform/embed/build/css/widget.css'
  import { onMount } from "svelte";
  interface Props {
    portableText: MarkComponentProps<{
    formId: string;
  }>;
  }

  let { portableText }: Props = $props();


  let { value } = $derived(portableText);


  let options = {
    lazy: true,
  }
  let typeform: HTMLElement = $state();
  onMount(() => {
    pkg.createWidget(value.formId, { container: typeform, ...options })
  })
</script>

{#if value.formId}
  <div class="wrapper" bind:this={typeform}></div>
{/if}

<style lang="postcss">
  .wrapper {
    width: 100%;
    height: 600px;
    margin: 0 auto;
  }

  @media (--tablet) {
    .wrapper {
      height: 1000px !important;
    }
  }
</style>
