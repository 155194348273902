<script lang="ts">
  import type { PortableText } from "@portabletext/svelte";
  import CheckmarkList from "../CheckmarkList.svelte";
  import CheckedBulletPoint from "./CheckedBulletPoint.svelte";

  interface Props {
    portableText: PortableText;
  }

  let { portableText }: Props = $props();
</script>

<CheckmarkList>
  {#each portableText.value.children as child}
    {#if child._type === "block"}
      <CheckedBulletPoint portableText={child} />
    {/if}
  {/each}
</CheckmarkList>
