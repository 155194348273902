<script lang="ts">
  import type { CustomBlockComponentProps } from "@portabletext/svelte";
  import type { ImageWithMeta } from "../types/shared";
  import Picture from "$lib/Picture.svelte";
  import { getSanityImageSizesObject } from "$lib/utils/getSanityImageSizesObject";
  import { breakpoints as br } from "$lib/utils/getBreakpoints";


  
  interface Props {
    class?: string;
    portableText: CustomBlockComponentProps<ImageWithMeta>;
  }

  let { class: classes = "", portableText }: Props = $props();

  let image = $derived(portableText?.value && {
    ...portableText.value,
    ...getSanityImageSizesObject(
      (portableText?.value?.asset?._ref || portableText?.value?.asset?._id), {
        sizes: [[1000], [1800]],
        fit: "min",
      }
    )
  })
</script>

<p
  class="image-component-container {classes}"
  data-aspectRatio={portableText?.value?.asset?.metadata?.dimensions?.aspectRatio}
>
  {#if image}
    <Picture
      src={image.src || ""}
      alt={image.alt || ""}
      srcset={image.srcset}
      srcsetWebp={image.srcsetWebp}
      sizes={`(min-width: ${br.desktop}) 65vw, (min-width: ${br.laptop}) 75vw, 100vw`}
      width={image?.asset?.metadata?.dimensions?.width}
      height={image?.asset?.metadata?.dimensions?.height}
    />
  {/if}
</p>

<style>
  p {
    /* applies to alt text when image is not available */
    font-style: italic;
  }
</style>
