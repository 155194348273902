<script lang="ts">
  import type { CustomBlockComponentProps } from "@portabletext/svelte";
  import type { SourceReference } from "../types/shared";

  interface Props {
    portableText: CustomBlockComponentProps<SourceReference>;
  }

  let { portableText }: Props = $props();
</script>

<span>{portableText.value.sourceReference}</span>
