import type { SanityImageSizes, SanityImageSizesOpt } from "$lib/types/shared";
import type { ImageFormat } from "@sanity/image-url/lib/types/types";
import { getSanityImageSrc as getSrc } from "$lib/utils/getSanityImageSrc";

// returns Sanity image URLs that must be created after database result
export const getSanityImageSizesObject = (
  id: string | null | undefined,
  opt: SanityImageSizesOpt,
  record?: { [key: string]: unknown; },
  client?: unknown
): SanityImageSizes | null => {
  client = typeof client === "undefined" ? undefined : client;
  const getSrcset = (id: string, opt: SanityImageSizesOpt, format?: ImageFormat): string[] =>
    opt.sizes.map((size) =>
      getSrc(id, {
        format: format || "jpg",
        width: size[0],
        height: size[1],
        fit: opt.fit,
        hotspot: opt.hotspot,
        crop: opt.crop,
        quality: opt.quality || 80,
        srcsetW: true,
      }, record, client)
    );
  // Use biggest size as default
  const biggestSize = opt.sizes[opt.sizes.length - 1];

  return id
    ? {
      src: getSrc(id, {
        width: biggestSize[0],
        height: biggestSize[1],
        fit: opt.fit,
        hotspot: opt.hotspot,
        crop: opt.crop,
        quality: opt.quality || 80,
      }, record, client),
      srcset: getSrcset(id, opt).join(", "),
      srcsetWebp: getSrcset(id, opt, "webp").join(", "),
    }
    : null;
};
