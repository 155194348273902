<script lang="ts">
  import type { CustomBlockComponentProps } from "@portabletext/svelte";
  import type { Reference } from "../types/shared";

  interface Props {
    portableText: CustomBlockComponentProps<Reference>;
  }

  let { portableText }: Props = $props();

  let title = $derived(portableText.value.title);
  let id = $derived(portableText.value.id);
  let slug = $derived(portableText.value.slug?.toLowerCase() || null);
  let url = $derived(portableText.value?.url);
  // TODO: redirecte id til lesbar lenke for produkt / artikkel
  let type = $derived(portableText.value._type);
  let prefix = $derived(
    type === "articleReference" ? "om/aktuelt/" : type === "productReference" ? "nettbutikk/" : ""
  );
  let target = $derived(
    type === "fileReference" ? 'target="_blank" rel="noopener noreferrer"' : ""
  );
  let href = $derived(type === "fileReference" && url ? url : `/${prefix}${slug || id}`);
</script>

<span><a {target} {href}>{title}</a></span>
