import type { SanityImageSizesOpt } from "$lib/types/shared";
import { getSanityImageSizesObject } from "$lib/utils/getSanityImageSizesObject";

const isNumber = (value: unknown): value is number => typeof value === "number";

export const getSanityPictureObject = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sanityImage: any,
  imageOpt: SanityImageSizesOpt,
  attributeValues?: { width?: number; height?: number; },
  client?: unknown
) => {
  const id = sanityImage?.asset?._id || sanityImage?.asset?._ref || null;
  const meta: {
    alt?: string | undefined | null;
    caption?: string | undefined | null;
    credit?: string | undefined | null;
  } = {};
  const attributes = attributeValues || {};
  const lqip = sanityImage?.asset?.metadata?.lqip
    ? { lqip: sanityImage.asset.metadata.lqip }
    : {};
  const palette = sanityImage?.asset?.metadata?.palette
    ? { palette: sanityImage.asset.metadata.palette }
    : {};
  const biggestWidth = imageOpt.sizes[imageOpt.sizes.length - 1][0];
  // get image meta values
  if (sanityImage?.alt) meta.alt = sanityImage.alt;
  if (sanityImage?.caption) meta.caption = sanityImage.caption;
  if (sanityImage?.credit) meta.credit = sanityImage.credit;
  // add hotspot to imageOpt if hotspot is defined in sanityImage
  if (sanityImage?.hotspot) imageOpt.hotspot = sanityImage.hotspot;
  // add crop to imageOpt if crop is defined in sanityImage
  if (sanityImage?.crop) imageOpt.crop = sanityImage.crop;

  // use width and height from size if is, and not explicitly defined
  if (
    imageOpt.sizes?.length > 1
    && imageOpt.sizes[imageOpt.sizes.length - 1][0]
    && imageOpt.sizes[imageOpt.sizes.length - 1][1]
    && !attributes.width
    && !attributes.height
  ) {
    attributes.width = imageOpt.sizes[imageOpt.sizes.length - 1][0];
    attributes.height = imageOpt.sizes[imageOpt.sizes.length - 1][1];
  } else if (
    sanityImage?.asset?.metadata?.dimensions?.width
    && sanityImage?.asset?.metadata?.dimensions?.height
    && isNumber(sanityImage?.crop?.top)
    && isNumber(sanityImage?.crop?.bottom)
    && isNumber(sanityImage?.crop?.left)
    && isNumber(sanityImage?.crop?.right)
  ) {
    // Calculate image dimensions based on crop
    // Note: Requires passing image record to Sanity's URL builder
    const dimensions = sanityImage.asset.metadata.dimensions;
    const cropWidth =
      dimensions.width -
      sanityImage.crop.left * dimensions.width -
      sanityImage.crop.right * dimensions.width;
    const cropHeight =
      dimensions.height -
      sanityImage.crop.top * dimensions.height -
      sanityImage.crop.bottom * dimensions.height;

    if (biggestWidth) {
      attributes.width = biggestWidth;
      attributes.height = Math.round((biggestWidth / cropWidth) * cropHeight);
    } else {
      attributes.width = cropWidth;
      attributes.height = cropHeight;
    }
  }

  const sanityImageSizesObject = getSanityImageSizesObject(id, imageOpt, sanityImage, client) || {};

  return sanityImageSizesObject
    ? {
      ...meta,
      ...attributes,
      ...sanityImageSizesObject,
      ...lqip,
      ...palette,
    }
    : null;
};

export default getSanityPictureObject;
